$(() => {
    function selectControls(control) {
        control.querySelector('input[type="radio"]').setAttribute('checked', 'checked');
    }

    function initPreselect() {
        document.querySelectorAll('.js-preselect').forEach(selectControls);
    }

    initPreselect();

    $(window).on('softpage:opened', initPreselect);

    // re-init after cms page refresh
    if (window.CMS) {
        CMS.$(window).on('cms-content-refresh', initPreselect);
    }
});
