import { initAjaxLoadItemsTrigger } from 'allink-core-static/js/modules/ajax-load-items';

$(() => {
    const options = {
        ajax_load_transition_duration: 100,
    };

    // init on page load
    initAjaxLoadItemsTrigger(options);

    // re-init when softpage has been opened
    $(window).on('softpage:opened', () => {
        initAjaxLoadItemsTrigger(options);
    });

    // re-init after cms page refresh
    if (window.CMS) {
        CMS.$(window).on('cms-content-refresh', () => {
            initAjaxLoadItemsTrigger(options);
        });
    }
});
