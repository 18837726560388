/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Teaser parallax scroll effect

*/
import simpleParallax from 'simple-parallax-js';

function initParallax() {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    if (isMobile === false) {
        const $images = $('.teaser--parallax .teaser__image');
        $images.on('lazyloaded', (event) => {
            const $image = $(event.target);

            if ($image.parent('.teaser--parallax')) {
                // eslint-disable-next-line
                new simpleParallax($image.get(0), {
                    scale: 1.3,
                    orientation: 'down',
                    transition: 'ease-out',
                });
            }
        });
    }
}

$(() => {
    // init on page load
    initParallax();

    // custom event
    $(window).on('initParallax', initParallax);

    // re-init after cms page refresh
    if (window.CMS) {
        CMS.$(window).on('cms-content-refresh', initParallax);
    }
});
