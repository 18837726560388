/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Overlay Menu Nav Toggle

*/

function initNavigation() {
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    Toggle a class when mobile nav is expanded

    */

    // init
    const $html = $('html');
    const $navToggle = $('.nav-toggle');
    const $overlayMenu = $('#overlay-menu');
    const $closeOverlay = $('[close-overlay]');
    const toggledAttr = 'data-menu-toggled';
    const toggledClass = 'is-active';
    const beforeToggledClass = 'before-is-active';
    const headerCompactModeClass = 'header-compact-mode';
    const overlayMenuEnabledClass = 'overlay-menu-enabled';
    const animationDuration = 400; // ms, must be same as in static/scss/navigation/_overlay-menu.scss
    let isAnimating = false;
    let isHeaderCompactModeActive = false;

    function openNavigation() {
        isAnimating = true;
        // before toggle
        $overlayMenu.addClass(beforeToggledClass);

        if ($html.hasClass(headerCompactModeClass)) {
            isHeaderCompactModeActive = true;
        }

        // animate
        setTimeout(() => {
            $html.addClass(overlayMenuEnabledClass);
            $html.addClass(headerCompactModeClass);
            $navToggle.addClass(toggledClass);
            $overlayMenu.addClass(toggledClass);
            $navToggle.attr(toggledAttr, '');
            isAnimating = false;
        }, 50);
    }

    function closeNavigation() {
        $html.removeClass(overlayMenuEnabledClass);
        $navToggle.removeClass(toggledClass);
        $overlayMenu.removeClass(toggledClass);

        if (!isHeaderCompactModeActive) {
            $html.removeClass(headerCompactModeClass);
        } else {
            isHeaderCompactModeActive = false;
        }

        // after animation is done
        setTimeout(() => {
            $overlayMenu.removeClass(beforeToggledClass);
            isAnimating = false;
        }, animationDuration);
    }

    // overlay menu toggle
    $navToggle.on('click', (e) => {
        e.preventDefault();

        // do nothing when already animating
        if (isAnimating) {
            return;
        }
        if ($navToggle.hasClass(toggledClass)) {
            closeNavigation();
        } else {
            openNavigation();
        }
    });

    // close overlay if element has set attribute
    $closeOverlay.on('click', (e) => {
        // do nothing when already animating
        if (isAnimating) {
            return;
        }
        // keep overlay open if target is on a different path
        if ($navToggle.hasClass(toggledClass) && e.currentTarget.pathname === window.location.pathname) {
            closeNavigation();
        }
    });

    // Close when hitting ESC
    $(document).keydown((e) => {
        const evt = e || window.event;
        let isEscape = false;
        if ('key' in evt) {
            isEscape = evt.key === 'Escape';
        } else {
            isEscape = evt.keyCode === 27;
        }
        if (isEscape) {
            // only call if the navigation is actually open
            if ($navToggle.hasClass(toggledClass)) {
                closeNavigation();
            }
        }
    });

    // Open Navigation when already open after cms re-init
    if ($html.hasClass(overlayMenuEnabledClass)) {
        openNavigation();
    }
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Init

*/

$(() => {
    // init on page load
    initNavigation();

    // custom events
    $(window).on('initNavigation', () => {
        initNavigation();
    });

    // re-init after cms page refresh
    if (window.CMS) {
        CMS.$(window).on('cms-content-refresh', () => {
            initNavigation();
        });
    }
});
