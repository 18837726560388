/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Swiper by iDangero.us

API:

http://idangero.us/swiper/api/

*/

import { initiSwiperInstances } from 'allink-core-static/js/modules/swiper';

$(() => {
    const options = {
        // define options here
    };

    // on page load
    initiSwiperInstances(options);

    // custom event
    $(window).on('initiSwiperInstances viewportWidthHasChanged softpage:opened', () => {
        initiSwiperInstances(options);
    });

    // re-init after cms page refresh
    if (window.CMS) {
        CMS.$(window).on('cms-content-refresh', () => {
            initiSwiperInstances(options);
        });
    }
});
