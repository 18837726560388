/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

SEO Accordion

*/

import initSEOAccordion from 'allink-core-static/js/modules/seo-accordion';

$(() => {
    const options = {
        duration: 400,
    };

    // init on page load
    initSEOAccordion(options);

    // re-init when softpage has been opened
    $(window).on('softpage:opened', () => {
        initSEOAccordion(options);
    });

    // re-init after cms page refresh
    if (window.CMS) {
        CMS.$(window).on('cms-content-refresh', () => {
            initSEOAccordion(options);
        });
    }
});
