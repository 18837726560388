import { initMap } from 'allink-core-static/js/modules/map';

$(() => {
    const options = {
        // define options here
    };

    // init on page load
    initMap(options);

    // re-init when softpage has been opened
    $(window).on('softpage:opened', () => {
        initMap(options);
    });

    // re-init after cms page refresh
    if (window.CMS) {
        CMS.$(window).on('cms-content-refresh', () => {
            initMap(options);
        });
    }
});
