$(() => {
    function toggleField() {
        const target = document.getElementById('js-toggle-charging');

        if (this.checked) {
            target.removeAttribute('disabled');
        } else {
            document.getElementById('id_charging_station').checked = false;
            target.setAttribute('disabled', 'disabled');
        }
    }

    function initFormToggle() {
        const toggle = document.getElementById('id_parking');

        if (toggle) {
            toggle.addEventListener('change', toggleField);
        }
    }

    initFormToggle();

    $(window).on('softpage:opened', initFormToggle);

    // re-init after cms page refresh
    if (window.CMS) {
        CMS.$(window).on('cms-content-refresh', initFormToggle);
    }
});
