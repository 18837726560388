$(() => {
    const options = {};

    // on page load
    initFragmentOffset(options);
});


function initFragmentOffset() {
    const $overlayMenu = $("[id^='anchor-']");
    $overlayMenu.css({ "margin-top": "-180px", "padding-top": "180px" });
}
